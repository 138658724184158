<script setup>
import { useAppStore } from '@/stores/app.js';
import TestingBrowsersIcon from '@/components/Icons/TestingBrowsersIcon.vue';

const appStore = useAppStore();
</script>

<template>
    <Transition>
        <div
            v-if="appStore.showMigrationHelp"
            class="migration-box"
        >
            <button
                type="button"
                class="absolute right-3 top-3"
                @click="appStore.showMigrationHelp = false"
            >
                <svg
                    class="h-4 w-4"
                    viewBox="-0.5 -0.5 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    height="32"
                    width="32"
                >
                    <path
                        d="M0.9573750000000001 11.5a10.541666666666668 10.541666666666668 0 1 0 21.083333333333336 0 10.541666666666668 10.541666666666668 0 1 0 -21.083333333333336 0Z"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                    <path
                        d="m15.564291666666668 7.43475 -8.131458333333333 8.131458333333333"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                    <path
                        d="M15.564291666666668 15.566208333333332 7.432833333333334 7.43475"
                        fill="none"
                        stroke="#000000"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                    ></path>
                </svg>
            </button>
            <div class="mr-3">
                <TestingBrowsersIcon class="h-6 w-6" />
            </div>
            <div class="text-xs">
                <div>{{ $t('support.migration_help_alert_title') }}</div>
                <RouterLink
                    :to="{ name: 'migration' }"
                    class="group mt-2 flex items-center"
                >
                    {{ $t('support.migration_help_alert_link') }}
                    <svg
                        class="ml-1 -rotate-45 transition-transform duration-200 group-hover:rotate-0"
                        height="20"
                        viewBox="-0.5 -0.5 24 24"
                        width="20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.9583333333333334 11.498083333333334a10.541666666666668 10.541666666666668 0 1 0 21.083333333333336 0 10.541666666666668 10.541666666666668 0 1 0 -21.083333333333336 0Z"
                            fill="#000000"
                            stroke="#ffffff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                        <path
                            d="m5.75 11.498083333333334 12.458333333333334 0"
                            fill="none"
                            stroke="#ffffff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                        <path
                            d="m13.895833333333334 7.185583333333334 4.3125 4.3125 -4.3125 4.3125"
                            fill="none"
                            stroke="#ffffff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1"
                        ></path>
                    </svg>
                </RouterLink>
            </div>
        </div>
    </Transition>
</template>

<style lang="postcss" scoped>
.migration-box {
    @apply relative mt-6 flex border-b-2 border-accent bg-[#d8e1f8] py-3 pl-3 pr-6 text-black;
}
</style>
