<script setup>
import CFProductBlockCol from '@/components/Dashboard/CFProductBlockCol.vue';
import CFAlert from '@/components/CFAlert.vue';
import { computed, onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import CFVerifyEmailAlert from '@/components/CFVerifyEmailAlert.vue';
import CFLayoutBase from '@/views/layouts/CFLayoutBase.vue';
import CFDashboardNotifications from '@/components/Dashboard/CFDashboardNotifications.vue';
import { useNotificationsStore } from '@/stores/notifications.js';
import { useSSLStore } from '@/stores/ssl.js';
import { useDomainsStore } from '@/stores/domains.js';
import { useUserStore } from '@/stores/user.js';
import Search1Icon from '@/components/Icons/Search1Icon.vue';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const userStore = useUserStore();

const search = ref('');

const products = ref([
    {
        name: t('dashboard.domains'),
        amount: 0,
        key: 'Domains',
        href: { name: 'domains' },
        order: { name: 'new domain' },
    },
    {
        name: t('dashboard.ssl_certificates'),
        amount: 0,
        key: 'SSLCertificates',
        href: { name: 'ssl' },
        order: { name: 'new ssl' },
    },
]);

const searchButtonClass = computed(() => {
    return search.value === '' ? 'text-white bg-white/20' : 'text-primary bg-white rounded-md';
});

const searchDomain = () => {
    if (search.value !== '') {
        router.push({ name: 'new domain', query: { q: search.value } });
    }
};

const onPaste = (e) => {
    search.value = e.clipboardData.getData('text/plain').replace(/\s/g, '').toLowerCase();
};

onMounted(async () => {
    const notificationsStore = useNotificationsStore();

    // Open the notifications dialog if the notifications query parameter is present
    if (Object.prototype.hasOwnProperty.call(route.query, 'notifications')) {
        notificationsStore.open();
    }

    const sslStore = useSSLStore();
    const domainStore = useDomainsStore();

    // Get existing items from the store before fetching them again
    products.value.map(
        (p) => (p.amount = sslStore[`total${p.key}`] ?? domainStore[`total${p.key}`])
    );

    await domainStore.getDomains();
    await sslStore.getSSLCertificates();

    // Set the items to the new values after fetching
    products.value.map(
        (p) => (p.amount = sslStore[`total${p.key}`] ?? domainStore[`total${p.key}`])
    );
});
</script>

<template>
    <CFLayoutBase>
        <div
            v-if="!userStore.isVerified"
            class="mx-auto flex h-full max-w-4xl flex-col justify-center space-y-10"
        >
            <h1 class="text-3xl">
                {{ t('dashboard.welcome') }}, <strong>{{ userStore.fullName }}</strong>
            </h1>

            <h2 class="text-xl font-bold">{{ t('order.how_can_we_help') }}</h2>

            <CFAlert color="warning">
                <span class="font-bold">{{ t('dashboard.complete_registration_alert') }}</span>
                <router-link
                    :to="{ name: 'complete registration' }"
                    class="ml-2 underline"
                >
                    {{ t('general.complete_registration') }}
                </router-link>
            </CFAlert>
        </div>

        <div
            v-else
            class="content dashboard"
        >
            <div
                class="w-full basis-full lg:w-2/5 lg:min-w-[650px] lg:basis-[650px] 2xl:min-w-[750px] 2xl:basis-[750px]"
            >
                <CFVerifyEmailAlert />

                <h1 class="mb-12 text-3xl lg:text-4xl lg:leading-10">
                    {{ t('dashboard.welcome') }}, <strong>{{ userStore.firstName }}</strong>
                </h1>

                <form
                    class="mb-[60px] flex w-full flex-col justify-between bg-primary p-6 text-secondary lg:h-[140px] lg:flex-row lg:items-center lg:p-10"
                    @submit.prevent="searchDomain"
                >
                    <label
                        for="search"
                        class="mb-2 flex items-start pr-4 text-xl text-white lg:mb-0 lg:max-w-[140px] lg:items-center lg:text-2xl lg:leading-[22px] 2xl:text-3xl 2xl:leading-[25px]"
                    >
                        {{ t('dashboard.register_domain') }}
                    </label>
                    <div class="flex h-[50px] w-full rounded-md shadow-sm lg:w-[480px]">
                        <div class="relative flex flex-1 focus-within:z-10">
                            <input
                                id="search"
                                v-model.trim="search"
                                type="text"
                                name="search"
                                :placeholder="t('dashboard.search_domain')"
                                class="block w-full rounded-none border-none bg-white/20 pl-4 text-base leading-none text-white placeholder:text-[#FFFFFF80] focus:ring-0"
                                @keydown.space.prevent
                                @paste.prevent="onPaste"
                            />
                        </div>
                        <div
                            :class="searchButtonClass"
                            class="relative inline-flex items-center text-base"
                        >
                            <button
                                v-if="search !== ''"
                                class="h-[50px] w-[60px]"
                                type="submit"
                            >
                                {{ $t('button.check') }}
                            </button>
                            <Search1Icon
                                v-else
                                class="mr-5 h-6 w-6"
                                @click="searchDomain"
                            ></Search1Icon>
                        </div>
                    </div>
                </form>

                <div
                    class="mb-12 flex flex-col justify-between space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0"
                >
                    <CFProductBlockCol
                        v-for="product in products"
                        :key="product.name"
                        :product="product"
                    />
                </div>

                <CFDashboardNotifications />
            </div>
        </div>
    </CFLayoutBase>
</template>
